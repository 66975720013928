import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import SectorPage from '../components/sectorPage'

const LuxuryPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/luxe" type="sector">
      <SEO 
        title="Classement des meilleurs sites du secteur du luxe"
        description="Quels sont les meilleurs sites du secteur du luxe de l'Observatoire de l'impact positif ? Analyse des sites de luxe français Dior, Chanel, Vuitton, Cartier, Hermes."
        path="/luxe" />

      <Breadcrumb url="/luxe" label="Luxe" /> 

      <SectorPage slug="luxury" type="sector" />
    </Layout>
  )
  }

export default LuxuryPage

